/* Tailwindのディレクティブ */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* ベーススタイル */
@layer base {
  body {
    @apply m-0 antialiased;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

/* 共通のユーティリティクラス */
@layer utilities {
  .gradient-primary {
    @apply bg-gradient-to-b from-blue-600 via-blue-500 to-blue-400;
  }
} 